import React from 'react';
import styled, { css } from 'styled-components';
import colors from '@monorepo-zazuu/shared/constants/colors';

interface ITextProps {
  color?: string;
  size?: number;
  test: string;
  align?: 'center' | 'left' | 'right';
  marginBottom?: number;
  marginTop?: number;
  marginLeft?: number;
  marginRight?: number;
  margin?: number;
  textDecoration?: 'line-through' | 'underline' | 'none';
  weight?: '300' | '400' | '500' | '600' | '700';
  ellipsis?: boolean;
  letterSpacing?: number;
  width?: string;
}

const TextStyled: any = styled.p`
  color: ${({ color }: any) => color || colors.gray.dark01};
  font-size: ${({ size = 16 }: any) => size / 10}rem;
  text-align: ${({ align = 'center' }: any) => align};
  margin: ${({ margin = 0 }: any) => margin / 10}rem;
  line-height: ${({ size = 16 }: any) => (size * 1.3) / 10}rem;
  margin-bottom: ${({ marginBottom = 0 }: any) => marginBottom / 10}rem;
  margin-top: ${({ marginTop = 0 }: any) => marginTop / 10}rem;
  margin-left: ${({ marginLeft = 0 }: any) => marginLeft / 10}rem;
  margin-right: ${({ marginRight = 0 }: any) => marginRight / 10}rem;
  font-weight: ${({ weight = '400' }: any) => weight};
  letter-spacing: ${({ letterSpacing = 1 }: any) => letterSpacing / 10}rem;
  text-decoration-line: ${({ textDecoration }: any) => (textDecoration || 'none')};
  width: ${({ width }: any) => width }

  ${({ ellipsis }: any) => ellipsis
    && css`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    }
  `}
`;

const Text: React.FC<ITextProps> = ({
  test,
  color,
  size,
  align,
  marginBottom,
  marginTop,
  marginLeft,
  marginRight,
  margin,
  weight,
  textDecoration,
  ellipsis,
  width,
}) => (
  <TextStyled
    color={color}
    size={size}
    align={align}
    marginBottom={marginBottom}
    marginTop={marginTop}
    marginLeft={marginLeft}
    marginRight={marginRight}
    margin={margin}
    weight={weight}
    textDecoration={textDecoration}
    ellipsis={ellipsis}
    width={width}
  >
    {test}
  </TextStyled>
);

export default Text;

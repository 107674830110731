import React from 'react';
import Modal from "../../../components/Modal";
import Text from "../../../components/Text";
import { PeriodCountSection, PeriodCountWrapper } from './styles';

export type IPeriodAppointmentCount = {
  first: number
  second: number
  third: number
}

export type IPeriodCountModalProps = {
  periods: IPeriodAppointmentCount
  onClose: () => void
  isOpen: boolean
}

export default function PeriodCountModal({
  periods,
  onClose,
  isOpen,
}: IPeriodCountModalProps) {
  return (
    <Modal visible={isOpen} closeModal={onClose}>
      <Text test="Quantidade de agendamentos em cada período" size={20} weight="700" />
      <PeriodCountSection>
        <PeriodCountWrapper>
          <Text test="Período" width="30%" />
          <Text test="Agendamentos" width="30%" />
        </PeriodCountWrapper>
        <PeriodCountWrapper>
          <Text test="08:00 às 11:30" width="30%" />
          <Text test={String(periods.first)} width="30%" />
        </PeriodCountWrapper>
        <PeriodCountWrapper>
          <Text test="11:30 às 15:00" width="30%" />
          <Text test={String(periods.second)} width="30%" />
        </PeriodCountWrapper>
        <PeriodCountWrapper>
          <Text test="15:00 às 18:30" width="30%" />
          <Text test={String(periods.third)} width="30%" />
        </PeriodCountWrapper>
      </PeriodCountSection>
    </Modal>
  );
}

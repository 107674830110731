import colors from '@monorepo-zazuu/shared/constants/colors'
import React, { useCallback, useMemo, useState } from 'react'
import { Collapse } from 'react-collapse'
import { FiChevronDown, FiChevronUp } from 'react-icons/fi'
import { toast } from 'react-toastify'
import IAppointment from '@monorepo-zazuu/shared/interfaces/IAppointment'
import Button from '../../../components/Button'
import Modal from '../../../components/Modal'
import Text from '../../../components/Text'
import Input from '../../../components/web/Input'
import masks from '../../../utils/masks'
import * as Styles from './styles'

interface IProps {
  visible: boolean
  handleCloseModal: () => void
  professional_id: string | undefined
  appointments: IAppointment[]
  handleAddToProfessional: (appointment: IAppointment) => void
}

const ModalAddAppointmentToProfessional: React.FC<IProps> = ({
  visible,
  handleCloseModal,
  professional_id,
  appointments = [],
  handleAddToProfessional
}) => {
  const [hour, setHour] = useState('')
  const [
    collapseAppointmentIndexToOpen,
    setCollapseAppointmentIndexToOpen
  ] = useState<string | undefined>(undefined)

  const appointmentsGroupByPeriod = useMemo(() => {
    return appointments.reduce((groups, curretnAppointment) => {
      const index = groups.findIndex(
        group => group.period === curretnAppointment.period
      )
      if (index >= 0) {
        groups[index] = {
          period: groups[index].period,
          appointments: [...groups[index].appointments, curretnAppointment]
        }
        return groups
      }
      return [
        ...groups,
        {
          period: curretnAppointment.period,
          appointments: [curretnAppointment]
        }
      ]
    }, [] as { period: string; appointments: IAppointment[] }[])
  }, [appointments])

  const handleToogleCollapse = useCallback(
    (appointment_id: string) => {
      if (appointment_id === collapseAppointmentIndexToOpen) {
        setCollapseAppointmentIndexToOpen(undefined)
        return
      }
      setCollapseAppointmentIndexToOpen(appointment_id)
    },
    [collapseAppointmentIndexToOpen]
  )

  const handleUpdateHour = useCallback(async () => {
    const appointment = appointments.find(
      appointment => appointment.id === collapseAppointmentIndexToOpen
    )
    if (!appointment || !professional_id || hour.length !== 5) {
      toast('Escolha um horário valido e selecione um agendamento', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        type: 'error'
      })
      return
    }
    const new_appointment = {
      ...appointment,
      professional_id: professional_id,
      hour: hour
    }

    handleAddToProfessional(new_appointment)
    setHour('')
  }, [
    collapseAppointmentIndexToOpen,
    professional_id,
    hour,
    appointments,
    handleAddToProfessional
  ])

  const applyMask = useCallback((value: string, mask: string) => {
    setHour(masks[mask](value))
  }, [])

  return (
    <Modal visible={visible} closeModal={handleCloseModal}>
      <Styles.Content>
        <Text
          test="Agendamentos não marcados"
          size={24}
          align="center"
          weight="600"
        />
        <Text test="Escolha o horário" size={24} align="center" weight="600" />
        <Styles.Row style={{ marginTop: 16 }}>
          <Input
            value={hour}
            onChange={e => applyMask(e.target.value, 'hours')}
            placeholder="horário"
            containerStyle={{
              maxWidth: 300
            }}
          />
          <Button
            behavior="primary"
            text="salvar"
            styleContainer={{ marginLeft: 16 }}
            onClick={() => handleUpdateHour()}
          />
        </Styles.Row>
        {appointmentsGroupByPeriod.map(appointmentGroup => (
          <Styles.Block key={appointmentGroup.period}>
            <Text
              test={appointmentGroup.period}
              align="left"
              weight="600"
              color={colors.primary.default}
            />
            {appointmentGroup.appointments.map(appointment => (
              <Styles.Card
                key={appointment.id}
                onClick={() => handleToogleCollapse(appointment.id)}
              >
                <Styles.CardHeader
                  selected={collapseAppointmentIndexToOpen === appointment.id}
                  status={appointment.status}
                >
                  <Text
                    test={`Tutor: ${appointment.customer.user.name}`}
                    color={colors.gray.white}
                    weight="600"
                  />
                  <Text
                    test={`Pet: ${appointment.pet.name}`}
                    color={colors.gray.white}
                    marginLeft={16}
                    weight="600"
                  />
                  {collapseAppointmentIndexToOpen === appointment.id ? (
                    <FiChevronUp
                      size={24}
                      color={colors.gray.white}
                      style={{ marginLeft: 'auto' }}
                    />
                  ) : (
                    <FiChevronDown
                      size={24}
                      color={colors.gray.white}
                      style={{ marginLeft: 'auto' }}
                    />
                  )}
                </Styles.CardHeader>
                <Styles.CardContent
                  selected={collapseAppointmentIndexToOpen === appointment.id}
                >
                  <Styles.CardAddress>
                    <Text
                      test={`${appointment.address.neighborhood}, ${
                        appointment.address.street
                      }, ${appointment.address?.number || 'sem número'}, ${
                        appointment.address.city
                      } - ${appointment.address.state}`}
                      align="left"
                      color={colors.gray.dark02}
                    />
                  </Styles.CardAddress>
                  <Collapse
                    isOpened={collapseAppointmentIndexToOpen === appointment.id}
                  >
                    <Styles.CollapseContent>
                      <Text
                        test={`Raça: ${appointment.pet?.breed?.name || 'SRD'}`}
                        color={colors.gray.dark02}
                      />
                      <Text
                        test={`Temperamento: ${
                          appointment.pet?.temperament || 'Não informado'
                        }`}
                        color={colors.gray.dark02}
                      />
                      <Text
                        test={`Serviço: ${appointment.service.name}`}
                        color={colors.gray.dark02}
                      />
                      <Text test="Itens:" color={colors.gray.dark02} />
                      {appointment.additionals.map((item, index) => (
                        <Text
                          key={index}
                          test={item.name}
                          color={colors.gray.dark02}
                        />
                      ))}
                      {appointment.observations && (
                        <>
                          <Text
                            align="left"
                            test={`Observações do agendamento:`}
                            color={colors.gray.dark02}
                          />
                          <Text
                            marginLeft={10}
                            align="left"
                            test={` •${appointment.observations}`}
                            color={colors.gray.dark02}
                          />
                        </>
                      )}
                      {appointment.customer.user.getPermanetObservations
                        .customer_observations.length > 0 && (
                        <>
                          <Text
                            align="left"
                            test={`Observações do Tutor:`}
                            color={colors.gray.dark02}
                          />
                          {appointment.customer.user.getPermanetObservations.customer_observations.map(
                            observation => {
                              return (
                                <Text
                                  marginLeft={10}
                                  align="left"
                                  test={` •${observation.description}`}
                                  color={colors.gray.dark02}
                                />
                              )
                            }
                          )}
                        </>
                      )}
                      {appointment.pet.getPermanetObservations.pet_observations
                        .length > 0 && (
                        <>
                          <Text
                            align="left"
                            test={`Observações do Pet:`}
                            color={colors.gray.dark02}
                          />
                          {appointment.pet.getPermanetObservations.pet_observations.map(
                            observation => {
                              return (
                                <Text
                                  marginLeft={10}
                                  align="left"
                                  test={` •${observation.description}`}
                                  color={colors.gray.dark02}
                                />
                              )
                            }
                          )}
                        </>
                      )}
                    </Styles.CollapseContent>
                  </Collapse>
                </Styles.CardContent>
              </Styles.Card>
            ))}
          </Styles.Block>
        ))}
        {appointments.length === 0 && (
          <Text test="Sem agendamentos" marginTop={32} />
        )}
      </Styles.Content>
    </Modal>
  )
}

export default ModalAddAppointmentToProfessional

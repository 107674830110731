import styled from "styled-components";

export const PeriodCountSection = styled.section`
  margin-top: 4px;
`;

export const PeriodCountWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  gap: 32px;
  padding: 12px 0;
  border: 1px solid black;
  margin: 2px;
`;
